<template>
  <v-app>
    <v-main class="test">
      <navigation-drawer v-if="isAuth" />
      <loader-bar />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { Auth } from "./store/common/auth";
import loaderBar from "./components/mycomponent/preview/dataLoaderBar.vue";
export default {
  name: "App",
  components: {
    loaderBar,
  },
  data: () => ({
    Auth,

    drawer: true,
    routeName: null,
  }),
  computed: {
    isAuth() {
      return Auth.token != null;
    },
  },
  mounted() {
    console.log(`Working on '${process.env.VUE_APP_BASE_URL}' mode`);

    if (localStorage.getItem("lang") == "ar") {
      this.$i18n.locale = "ar";
      this.$vuetify.rtl = true;
    } else if (localStorage.getItem("lang") == "ar") {
      this.$i18n.locale = "en";
      this.$vuetify.rtl = false;
    } else {
      this.$vuetify.rtl = true;
    }
  },
};
</script>
<style lang="scss">
*::-webkit-scrollbar {
  width: 0px;
}
</style>
