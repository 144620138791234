<template>
  <v-row class="justify-center add-btn  mt-4 ">
    <v-btn
      :loading="loader"
      :disabled="disable"
      @click="doAction"
      class="next ma-2"
    >
      {{ $t("next") }}
    </v-btn>
    <v-btn @click="doBack" class="back ma-2">
      {{ $t("back") }}
    </v-btn>
  </v-row>
</template>
<script>
import { Store } from "@/store/tables";
export default {
  props: {
    name: {
      type: String,
    },
    disable: {
      type: Boolean,
    },
    action: {
      type: Function,
    },
    back: {
      type: Function,
    },
    loader: {
      type: Boolean,
    },
  },
  methods: {
    doAction() {
      this.action();
    },
    doBack() {
      this.back();
    },
  },
  created() {
    // if (this.$exersice.exercise.name == null) {
    //   this.$router.push("/Exercise/add-exercise");
    // }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";

.add-btn {
  align-self: flex-end;
  .next {
    background-color: $myBlue !important;
    color: white !important;
    width: 10%;
    height: 50px !important;
  }
  .back {
    background-color: white !important;
    color: $myBlue !important;
    width: 10%;
    height: 50px !important;
  }
}
</style>
