<template>
  <div v-if="src != null" class="image-preview">
    <v-card-actions style="height: 20px;">
      <v-spacer></v-spacer>
      <v-icon @click="resetInput">mdi-close-circle</v-icon>
    </v-card-actions>
    <v-fade-transition mode="out-in">
      <img class="img" :src="src" alt="" />
    </v-fade-transition>
  </div>
  <v-card
    @click="chooseImg"
    v-else
    :class="{ disable: disabled }"
    class="image-preview-off d-flex align-center text-center"
  >
    <v-progress-circular
      v-if="loader && this.data"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <v-fade-transition v-else mode="out-in">
      <div>
        <v-icon x-large>mdi-image-off</v-icon>
        <p>{{ $t("answer_image") }}</p>
      </div>
    </v-fade-transition>
    <add-popup
      @fileChoosen="setsrc($event)"
      @fileChoosenId="setSrcWithId($event)"
      @closePopup="dialog = false"
      :status="dialog"
      :reset="src"
      :accept="'image/*'"
      :enablePlugin="true"
    ></add-popup>
  </v-card>
</template>
<script>
import addPopup from "@/components/mycomponent/base/addPopup.vue";
import { File } from "@/store/file-system/file";
import { State } from "@/store/state";

export default {
  props: ["disabled", "data"],
  components: {
    addPopup,
  },
  data() {
    return {
      File,
      State,
      dialog: false,
      src: null,
      myState: null,
    };
  },
  methods: {
    setsrc(file) {
      this.dialog = false;
      this.src = file;
    },
    async setSrcWithId(id) {
      this.myState = new State();
      await File.show(this.myState, id);
      const blob = new Blob([this.myState.value], {});
      const imgUrl = URL.createObjectURL(blob);
      this.src = imgUrl;
      this.$emit("fileIdLoaded", id);
    },
    resetInput() {
      this.src = null;
      this.$emit("cancelFile");
    },
    chooseImg() {
      if (!this.disabled) {
        this.dialog = true;
      }
    },
  },
  computed: {
    imgSrc() {
      let data = URL.createObjectURL(this.src);
      return data;
    },
    loader() {
      if (this.data) {
        return this.myState.loading;
      }
      return false;
    },
  },
  created() {
    if (this.data) {
      this.setSrcWithId(this.data);
    }
  },
  watch: {
    data(val) {
      if (val == null) this.src = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.image-preview-off {
  width: 100%;
  aspect-ratio: 2/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.image-preview {
  box-shadow: none;
  border: none;
  width: 100%;
  aspect-ratio: 2/1;
  border-radius: 10px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.disable {
  cursor: no-drop;
  background-color: whitesmoke !important;
}
</style>
