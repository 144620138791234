import i18n from "@/i18n";
import toast from "@/plugins/toast";
import router from "../../router";
import { State } from "@/store/state";
import { Request } from "@/network/request";

export default class auth {
  endPoint = "login";
  token = null;
  userType = "null";
  loginState = new State();
  async login(payload) {
    let request = new Request({
      method: "post",
      endpoint: `/${this.endPoint}`,
      data: payload,
    });

    await this.loginState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.token = value.token;
          $cookies.set("token", value.token, "7d");
          this.userType = value.type;

          router.push("/");
          toast(
            i18n.t("login success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
        },
        onError: (error) => {
          if (!error.type)
            toast(
              i18n.t("ERR_INTERNET_DISCONNECTED"),
              { timeout: 4000, rtl: true },
              "error"
            );
          else toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
}

export const Auth = new auth();
