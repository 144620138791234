<template>
  <v-dialog
    v-model="status"
    transition="dialog-top-transition"
    :max-width="getWidth"
    @click:outside="closePopup"
    @keydown.esc="closePopup"
  >
    <div style="background-color: white; ">
      <v-card-title class="d-block">
        <div v-if="!addToPlugin">
          <v-row v-if="!move" class="title-row justify-space-between mb-2">
            <v-col v-if="!plugin" lg="3"
              ><v-btn v-if="enablePlugin" @click="plugin = true" class="btn">{{
                $t("Add from the plugin library")
              }}</v-btn></v-col
            >
            <v-col v-if="plugin" lg="3"
              ><v-btn @click="plugin = false" class="btn">{{
                $t("Add from my device")
              }}</v-btn></v-col
            >

            <v-col
              @click="back"
              class="d-flex justify-end"
              v-if="plugin"
              lg="2"
            >
              <!-- <operation-menu /> -->
              <v-icon
                style="cursor: pointer;"
                v-if="parent"
                class="ma-1"
                x-large
                >mdi-arrow-left</v-icon
              >
            </v-col>
          </v-row>
          <v-row v-else class="justify-space-between align-center mb-2">
            <v-col class="d-flex" lg="2">
              <v-btn
                class="ma-1"
                v-if="move"
                @click="moveFolder"
                color="success"
                >{{ $t("move here") }}</v-btn
              >
              <v-btn
                class="ma-1"
                @click="cancelMoveFolder"
                v-if="move"
                color="error"
                >{{ $t("cancel") }}</v-btn
              >
            </v-col>
            <v-col class="d-flex justify-end" v-if="plugin" lg="2">
              <!-- <operation-menu /> -->
              <v-icon
                @click="back"
                style="cursor: pointer;"
                v-if="parent"
                class="ma-1"
                x-large
                >mdi-arrow-left</v-icon
              >
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row v-if="!move" class="title-row justify-space-between mb-2">
            <v-col v-if="plugin" lg="3"
              ><v-btn
                :disabled="!enableCreateFile"
                :loading="setFileState"
                @click="createFile"
                class="btn"
                >{{ $t("upload") }}</v-btn
              ></v-col
            >
            <v-col class="text-center">
              <h4>{{ $t("choose folder to uupload") }}</h4>
            </v-col>
            <v-col
              @click="back"
              class="d-flex justify-end"
              v-if="plugin"
              lg="2"
            >
              <!-- <operation-menu /> -->
              <v-icon
                style="cursor: pointer;"
                v-if="parent"
                class="ma-1"
                x-large
                >mdi-arrow-left</v-icon
              >
            </v-col>
          </v-row>
        </div>
        <v-row style="overflow: hidden;" class="justify-center" v-if="!plugin">
          <!-- <v-progress-circular
            v-if="loader"
            :rotate="-90"
            :size="300"
            :width="15"
            :value="progress"
            color="primary"
          >
            {{ progress }}
          </v-progress-circular> -->
          <local-form
            @fileValue="setFile($event)"
            :reset="reset"
            :accept="accept"
          />
        </v-row>
        <plugin-form
          @fileChoosenWithId="fileChoosen($event)"
          @moveFolder="setMoveFile($event)"
          @setParent="parent = $event.id"
          :parent="this.parent"
          :lg="3"
          :md="4"
          :sm="6"
          :cols="6"
          v-else
        >
        </plugin-form>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup" color="blue darken-1" text>
          {{ $t("back") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-dialog>
</template>
<script>
import localForm from "./local/index.vue";
import { Folder } from "@/store/file-system/index";
import { File } from "@/store/file-system/file";

export default {
  props: ["status", "reset", "accept", "enablePlugin", "loader", "fromLibrary"],
  components: {
    localForm,
  },
  data() {
    return {
      plugin: false,
      parent: null,
      move: false,
      moveFile: null,
      addToPlugin: false,
      file: null,
      File,
      Folder,
    };
  },

  computed: {
    dialogStatus() {
      return this.status;
    },
    progress() {
      return File.progress;
    },
    getWidth() {
      if (this.plugin) {
        return "900px";
      } else {
        return "600px";
      }
    },
    setFileState() {
      return File.postState.loading;
    },
    enableCreateFile() {
      return "parent" in Folder.data;
    },
  },
  methods: {
    fileChoosen(id) {
      this.$emit("fileChoosenId", id);
      this.closePopup();
    },
    closePopup() {
      this.$emit("closePopup");
      this.plugin = false;
      this.moveFile = null;
      this.move = false;
    },
    setFile(file) {
      this.$emit("fileChoosen", file);
      this.closePopup();
    },
    back() {
      if (Folder.data.parent) {
        Folder.show(Folder.data.parent.id);
      } else {
        Folder.get({ page: 1, parent_id: "null" });
        this.parent = null;
      }
    },
    setMoveFile(data) {
      this.move = true;
      this.moveFile = data;
      Folder.get({ page: 1, parent_id: "null" });
    },
    moveFolder() {
      if ("parent" in Folder.data) {
        Folder.put(this.moveFile.id, { parent_id: Folder.data.id });
      } else {
        Folder.put(this.moveFile.id, { parent_id: null });
      }

      this.moveFile = null;
      this.move = false;
    },
    cancelMoveFolder() {
      this.moveFile = null;
      this.move = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";
.title-row {
  .title {
    color: $myBlue;
  }
  .btn {
    color: white !important;
    background-color: $myBlue !important;
    span {
      font-size: 0.5vw;
    }
  }
}
.add-onSide {
  width: 90%;
  margin: auto;
  height: 300px;
  img {
    width: 200px;
    height: 200px;
  }
}
.base {
  width: 100%;
  height: 100%;
  border-radius: 10px !important;
}
h4 {
  font-size: 1vw;
}
</style>
