import Vue from "vue";
import axios from "axios";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import App from "./App.vue";
import Chartist from "chartist";

//plugins
import VueAxios from "vue-axios";
import DashboardPlugin from "./material-dashboard";
import { i18n } from "./i18n";
import { Exercise } from "./store/exercise/add";
import dataTable from "./components/mycomponent/base/dataTable.vue";
import question from "@/components/mycomponent/preview/audio-text.vue";
import answerOption from "@/components/mycomponent/preview/choose-answer-option.vue";
import audioPreview from "./components/mycomponent/preview/audio.vue";
import videoPreview from "./components/mycomponent/preview/video.vue";
import btns from "@/components/mycomponent/base/btnGroup.vue";
import stepper from "@/components/mycomponent/base/stepper.vue";
import pulseLoader from "vue-spinner/src/RiseLoader.vue";
import pagination from "./components/mycomponent/base/pagination.vue";
import deleteItem from "@/pages/Dashboard/age-group/delete.vue";
import pluginForm from "./components/mycomponent/base/plugin/index";
import navigationDrawer from "./components/mycomponent/base/navigation-drawer";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import autoComplete from "./components/mycomponent/base/autoComplete.vue";
import VueCookies from "vue-cookies";

//public data and methods
Vue.prototype.$exercise = Exercise;
Vue.prototype.$isEmail = function(str) {
  const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailPattern.test(str) || i18n.t("Invalid email address");
};
Vue.prototype.$validatePasswords = function(password1, password2) {
  // Check if passwords are the same
  if (password1 !== password2) {
    return false;
  }

  // Check if passwords are at least 8 characters long
  if (password1.length < 8 || password2.length < 8) {
    return false;
  }

  // Passwords are valid
  return true;
};

Vue.prototype.$Chartist = Chartist;

//plugins use
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.use(VueCookies, { expires: "7d" });

//component use

Vue.component("dataTable", dataTable);
Vue.component("question", question);
Vue.component("audioPreview", audioPreview);
Vue.component("videoPreview", videoPreview);
Vue.component("btns", btns);
Vue.component("stepper", stepper);
Vue.component("answerOption", answerOption);
Vue.component("pulseLoader", pulseLoader);
Vue.component("pagination", pagination);
Vue.component("deleteItem", deleteItem);
Vue.component("pluginForm", pluginForm);
Vue.component("navigationDrawer", navigationDrawer);
Vue.component("deletePopup", deletePopup);
Vue.component("autoComplete", autoComplete);

/* eslint-disable no-new */
const app = new Vue({
  router: router,
  store: store,
  el: "#app",
  vuetify,
  i18n,
  render: (h) => h(App),
});

store.$app = app;
