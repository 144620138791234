<template>
  <md-toolbar
    style="height: 40px;"
    md-elevation="0"
    class="md-transparent mb-8"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start d-block ">
        <h2 class="primary--text">
          {{ $t($route.name) }}
        </h2>
        <h3 class="mt-n4">
          {{ $t($exercise.exerciseName) }}
        </h3>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { Store } from "@/store/tables";

export default {
  data() {
    return {
      Store,
      selectedEmployee: "",
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
      documentationLink:
        "https://vue-material-dashboard-laravel.creative-tim.com/documentation/",
      downloadUrl:
        "https://www.creative-tim.com/product/vue-material-dashboard-laravel",
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    goToNotifications() {
      this.$router.push({ name: "Notifications" });
    },
    goToUsers() {
      this.$router.push({ name: "User Profile" });
    },
  },
  computed: {
    exerciseName() {
      return this.$route.params.exerciseName;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";
h2 {
  color: $myBlue;
  font-weight: 700;
  margin: 20px;
}
h3 {
  color: $myBlue;
  font-weight: 300;
  margin: 20px;
  margin-top: 10px;
}
</style>
