<template>
  <v-dialog
    v-model="status"
    transition="dialog-top-transition"
    :max-width="getWidth"
    @click:outside="closePopup"
    @keydown.esc="closePopup"
  >
    <div style="background-color: white; ">
      <v-card-title class="d-block">
        <div v-if="!addToPlugin">
          <v-row v-if="!move" class="title-row justify-space-between mb-2">
            <v-col v-if="!plugin" lg="3"
              ><v-btn v-if="enablePlugin" @click="plugin = true" class="btn">{{
                $t("Add from the plugin library")
              }}</v-btn></v-col
            >
            <v-col v-if="plugin" lg="3"
              ><v-btn @click="plugin = false" class="btn">{{
                $t("Add from my device")
              }}</v-btn></v-col
            >

            <v-col
              @click="back"
              class="d-flex justify-end"
              v-if="plugin"
              lg="2"
            >
              <!-- <operation-menu /> -->
              <v-icon style="cursor: pointer;" v-if="true" class="ma-1" x-large
                >mdi-arrow-left</v-icon
              >
            </v-col>
          </v-row>
          <v-row v-else class="justify-space-between align-center mb-2">
            <v-col class="d-flex" lg="2">
              <v-btn
                class="ma-1"
                v-if="move"
                @click="moveFolder"
                color="success"
                >{{ $t("move here") }}</v-btn
              >
              <v-btn
                class="ma-1"
                @click="cancelMoveFolder"
                v-if="move"
                color="error"
                >{{ $t("cancel") }}</v-btn
              >
            </v-col>
            <v-col class="d-flex justify-end" v-if="plugin" lg="2">
              <!-- <operation-menu /> -->
              <v-icon
                @click="back"
                style="cursor: pointer;"
                v-if="parent"
                class="ma-1"
                x-large
                >mdi-arrow-left</v-icon
              >
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row v-if="!move" class="title-row justify-space-between mb-2">
            <v-col v-if="plugin" lg="3"
              ><v-btn
                :disabled="!enableCreateFile"
                :loading="setFileState"
                @click="createFile"
                class="btn"
                >{{ $t("upload") }}</v-btn
              ></v-col
            >
            <v-col class="text-center">
              <h4>{{ $t("choose folder to uupload") }}</h4>
            </v-col>
            <v-col
              @click="back"
              class="d-flex justify-end"
              v-if="plugin"
              lg="2"
            >
              <!-- <operation-menu /> -->
              <v-icon
                style="cursor: pointer;"
                v-if="parent"
                class="ma-1"
                x-large
                >mdi-arrow-left</v-icon
              >
            </v-col>
          </v-row>
        </div>
        <v-row
          style="overflow: hidden;"
          class="justify-center"
          :style="{ display: plugin ? 'none' : 'block' }"
        >
          <local-form
            @fileValue="setFile($event)"
            :reset="reset"
            :accept="accept"
          />
        </v-row>
        <plugin-form
          :style="{ display: plugin ? 'block' : 'none' }"
          @fileChoosenWithId="fileChoosen($event)"
          @moveFolder="setMoveFile($event)"
          @setParent="parent = $event.id"
          :fromBase="false"
          :page="page"
          :parent="this.parent"
          :accept="accept"
          :lg="3"
          :md="6"
          :sm="6"
          :cols="12"
        >
        </plugin-form>
      </v-card-title>
      <v-card-actions class="d-flex justify-space-between ">
        <v-spacer></v-spacer>
        <v-col lg="10">
          <pagination
            v-if="!parent & plugin & !paginationLoader"
            @updatePage="updatePage($event)"
            :data="meta"
          />
        </v-col>
        <v-btn
          class="mt-4"
          :loading="loader"
          @click="closePopup"
          color="blue darken-1"
          text
        >
          {{ $t("back") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-dialog>
</template>
<script>
import localForm from "./local/index.vue";
import { Folder } from "@/store/file-system/index";
import { File } from "@/store/file-system/file";

export default {
  props: ["status", "reset", "accept", "enablePlugin", "loader", "fromLibrary"],
  components: {
    localForm,
  },
  data() {
    return {
      page: 1,
      plugin: true,
      parent: null,
      move: false,
      moveFile: null,
      addToPlugin: false,
      file: null,
      File,
      Folder,
    };
  },

  computed: {
    meta() {
      return Folder.data.meta;
    },
    paginationLoader() {
      return Folder.getState.loading;
    },
    dialogStatus() {
      return this.status;
    },
    getWidth() {
      if (this.plugin) {
        return "900px";
      } else {
        return "600px";
      }
    },
    setFileState() {
      return File.postState.loading;
    },
    enableCreateFile() {
      return "parent" in Folder.data;
    },
  },
  methods: {
    async createFile() {
      let payload = {
        name: this.file.name.substring(0, this.file.name.indexOf(".")),
        file: this.file,
      };
      let state = { state: true };
      await File.post(payload, state);
      if (!state.state) {
        this.addToPlugin = false;
        this.$emit("fileChoosenId", File.postState.value.id);
      }
      this.addToPlugin = false;
    },
    updatePage(event) {
      this.page = event;
    },

    fileChoosen(id) {
      this.$emit("fileChoosenId", id);
    },
    closePopup() {
      this.$emit("closePopup");
      // this.plugin = false;
      this.moveFile = null;
      this.move = false;
      this.addToPlugin = false;
    },
    setFile(file) {
      // this.$emit("fileChoosen", file);
      this.plugin = true;
      this.file = file;
      this.addToPlugin = true;
    },
    async back() {
      if (Folder.data.parent) {
        Folder.show(Folder.data.parent.id, {
          onSuccess: () => {
            Folder.itemsBreadcrumbs.pop();
          },
        });
      } else {
        await Folder.get({ page: 1, parent_id: "null" });
        this.parent = null;
      }
    },
    setMoveFile(data) {
      this.move = true;
      this.moveFile = data;
      Folder.get({ page: 1, parent_id: "null" });
    },
    moveFolder() {
      if ("parent" in Folder.data) {
        Folder.put(this.moveFile.id, { parent_id: Folder.data.id });
      } else {
        Folder.put(this.moveFile.id, { parent_id: null });
      }

      this.moveFile = null;
      this.move = false;
    },
    cancelMoveFolder() {
      this.moveFile = null;
      this.move = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";
.title-row {
  .title {
    color: $myBlue;
  }
  .btn {
    color: white !important;
    background-color: $myBlue !important;
    span {
      font-size: 0.5vw;
    }
  }
}
.add-onSide {
  width: 90%;
  margin: auto;
  height: 300px;
  img {
    width: 200px;
    height: 200px;
  }
}
.base {
  width: 100%;
  height: 100%;
  border-radius: 10px !important;
}
h4 {
  font-size: 1vw;
}
</style>
