import { State } from "../state";
import { Request } from "@/network/request";
import { Folder } from "./index";
import i18n from "@/i18n";

import toast from "../../plugins/toast";
import axios from "axios";
export default class file {
  uploadData = [];
  getState = new State();
  postState = new State();
  showState = new State();
  putState = new State();
  deleteState = new State();
  itemsBreadcrumbs = [
    {
      text: i18n.t("plugin_library"),
      disabled: false,
      href: "breadcrumbs_dashboard",
    },
  ];
  data = {
    children: null,
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };

  async post(data, state) {
    let check = Folder.data.files.filter((item) => item.name == data.name);
    if (check.length > 0) {
      toast(
        i18n.t("folder name already exists"),
        { timeout: 4000, rtl: true },
        "error"
      );
      return;
    }
    let cancelToken = axios.CancelToken.source();
    let form = new FormData();
    for (let key in data) {
      form.append(`${key}`, data[key]);
    }
    form.append("folder_id", Folder.data.id);

    let request = new Request({
      method: "post",
      endpoint: `/file`,
      data: form,
      cancelToken: cancelToken.token,
    });

    let isUploading = false; // Flag variable

    await this.postState.trigger(
      async () => {
        for (let i = 0; i < this.uploadData.length; i++) {
          const item = this.uploadData[i];
          if (data.name == item.name) {
            toast(
              i18n.t("file in uploading"),
              { timeout: 4000, rtl: true },
              "error"
            );
            isUploading = true; // Set the flag to true
            return false;
          }
        }

        // Continue with the upload process if the flag is still false
        let progress = {
          name: null,
          percent: 0,
        };
        progress.name = data.name;
        this.uploadData.push(progress);
        return await request.send((progressEvent) => {
          progress.percent = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          let index = this.uploadData.findIndex(
            (obj) => obj.name == progress.name
          );
          if (index >= 0) {
            this.uploadData[index].percent = progress.percent;
            console.log(index);
          } else {
            this.uploadData.push(progress);
          }
        });
      },
      {
        onSuccess: (value) => {
          if (isUploading) return; // Return if the flag is true
          let index = this.uploadData.findIndex((obj) => obj.name == data.name);
          this.uploadData.splice(index, 1);
          this.progress = 0;
          state.state = false;
          if ("parent" in Folder.data) {
            Folder.show(Folder.data.id);
          } else {
            Folder.get({ page: 1, parent_id: "null" });
          }
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "success"
          );
          Folder.show(Folder.data.id);
        },
        onError: (error) => {
          console.log(error);
          cancelToken.cancel("Post request canceled by user.");
          let index = this.uploadData.findIndex((obj) => obj.name == data.name);
          this.uploadData.splice(index, 1);
          this.progress = 0;
          toast(
            i18n.t("something wrong in") + " " + data.name,
            { timeout: 4000, rtl: true },
            "error"
          );
        },
      }
    );
  }

  async show(state, id, endpoint = "/file") {
    let request = new Request({
      method: "get",
      endpoint: `${endpoint}/${id}`,
      responseType: "arraybuffer",
    });

    await state.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {},
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async put(id, data) {
    let check = Folder.data.files.filter((item) => item.name == data.name);
    if (check.length > 0) {
      toast(
        i18n.t("folder name already exists"),
        { timeout: 4000, rtl: true },
        "error"
      );
      return;
    }
    if (!Folder.data.id) {
      toast(
        i18n.t("cant move to root folder"),
        { timeout: 4000, rtl: true },
        "error"
      );
      return;
    }
    let request = new Request({
      method: "put",
      endpoint: `/file/${id}`,
      data: data,
    });

    await this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(i18n.t("edit success"), { timeout: 4000, rtl: true }, "sucess");
          Folder.show(Folder.data.id, {
            onSuccess: () => {
              console.log("hhj");
            },
          });
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  delete(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/file/${id}`,
    });

    this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
          if ("parent" in Folder.data) {
            Folder.show(Folder.data.id);
          } else {
            Folder.get({ page: 1, parent_id: "null" });
          }
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
}
export const File = new file();
