import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./locales/en.json";
import ar from "./locales/ar.json";

Vue.use(VueI18n);

const messages = {
  en: {
    ...en,
  },
  ar: {
    ...ar,
  },
};

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "ar",
  locale: "ar",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ar",
  messages,
});
export default i18n;
