<template>
  <div>
    <v-container
      @click="closeAllTabs"
      @click.right="mouseMove"
      v-click-outside="closeAllTabs"
      class="folder-wrapper d-block"
    >
      <v-breadcrumbs :items="breadcrumbsItems" class="mb-6">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item @click="moveByBreadCrumbs(item)">
            <span
              :class="{
                'primary--text':
                  item !== breadcrumbsItems[breadcrumbsItems.length - 1],
                disable: item === breadcrumbsItems[breadcrumbsItems.length - 1],
              }"
            >
              {{ $t(item.text).toUpperCase() }}</span
            >
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </template>
      </v-breadcrumbs>
      <v-row v-if="!indexLoader">
        <v-col
          style="height: 100%;"
          v-for="(item, i) in folders.children"
          :key="i"
          :lg="lg"
          :md="md"
          :sm="sm"
          :cols="cols"
        >
          <folder
            @openFolder="openFolder($event)"
            @moveFolder="moveFolder($event)"
            @renameFolder="renameFolder($event)"
            @deleteFolder="deleteFolder($event)"
            @rightClick="showSettings = false"
            :item="item"
            :close="closeFolderSettings"
          />
        </v-col>
      </v-row>
      <v-row v-if="!indexLoader">
        <template v-for="(item, i) in folders.files">
          <v-col
            v-if="true"
            :key="i"
            :lg="lg"
            :md="md"
            :sm="sm"
            :cols="cols"
            @click.right="showSettings = false"
          >
            <file
              :item="item"
              @fileChoosenWithId="fileChoosen($event)"
              @moveFile="moveFile($event)"
              @renameFile="renameFile($event)"
              @deleteFile="deleteFile($event)"
              @rightClick="showSettings = false"
              :fromBase="fromBase"
              :accept="accept"
              :close="closeFolderSettings"
              :deleteLoader="deleteLoader"
            />
          </v-col>
        </template>
      </v-row>
      <div
        v-if="indexLoader"
        style="height: 100%;"
        class="d-flex justify-center align-center"
      >
        <drive-loader> </drive-loader>
      </div>
      <div
        @click.right="mouseMove"
        @click="closeAllTabs"
        v-if="
          Folder.data.children.length == 0 &&
            Folder.data.files.length == 0 &&
            !indexLoader
        "
        class=" d-flex align-center justify-center folder-wrapper"
      >
        <div class="text-center">
          <v-icon size="1000%">mdi-image-off</v-icon>
          <p class="font-weight-medium">{{ $t("no data") }}</p>
        </div>
      </div>
    </v-container>

    <v-list
      style="position:absolute"
      :style="[{ top: mousePosY + 'px' }, { left: mousePosX + 'px' }]"
    >
      <v-expand-transition>
        <v-list v-if="showSettings" class=" settings">
          <v-list-item
            class="item"
            v-for="(item, index) in items"
            :key="index"
            @click="item.action"
          >
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openAddFile" v-if="parent" class="item">
            <v-list-item-title>{{ $t("add_new_file") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-expand-transition>
    </v-list>
    <create-folder
      v-if="createFolderState.state"
      @closePopup="createFolderState.state = false"
      @submit="createFolder($event)"
      :loading="addFolderLoader"
      :dialog="createFolderState.state"
    />
    <create-file
      @fileChoosen="addFile($event)"
      @closePopup="addFileState.state = false"
      :status="addFileState.state"
      :enablePlugin="false"
      :loader="addFileLoader"
    />
  </div>
</template>
<script>
/* eslint-disable*/
import folder from "./folder.vue";
import file from "./file.vue";
import { Folder } from "@/store/file-system/index";
import { File } from "@/store/file-system/file";
import createFolder from "./dialog.vue";
import createFile from "@/components/mycomponent/base/addPopup2.vue";
import driveLoader from "../driveLoader.vue";

export default {
  props: ["page", "lg", "md", "sm", "cols", "parent", "accept", "fromBase"],
  components: {
    folder,
    createFolder,
    createFile,
    file,
    driveLoader,
  },
  data() {
    return {
      // Index Variables
      parent: null,
      mousePosX: 0,
      mousePosY: 0,
      items: [{ title: "create_new_folder", action: this.showCreateDialog }],
      showSettings: false,
      // Folder Variables

      Folder,
      createFolderState: {
        state: false,
      },
      closeFolderSettings: false,
      // File Variables

      File,
      addFileState: {
        state: false,
      },
    };
  },

  methods: {
    typeMapper(type) {
      const exercise = new Map([
        ["audio/*", "audio"],
        ["image/*", "image"],
        ["video/*", "video"],
      ]);
      return exercise.get(type);
    },

    async moveByBreadCrumbs(item) {
      if (Folder.get.loading) return;
      let index = this.breadcrumbsItems.indexOf(item);
      if (this.breadcrumbsItems.length - 1 == index) return;
      else if (index == 0) {
        Folder.itemsBreadcrumbs.splice(1);
        await Folder.get({ page: 1, parent_id: "null" });
        this.parent = null;

        return;
      }

      await Folder.show(item.id, {
        onSuccess: () => {
          Folder.itemsBreadcrumbs.splice(index + 1);
        },
      });
    },

    // Index Methods
    closeAllTabs() {
      this.closeFolderSettings = true;
      this.showSettings = false;

      setTimeout(() => {
        this.closeFolderSettings = false;
      }, 10);
    },
    mouseMove(event) {
      this.mousePosX = event.clientX;
      this.mousePosY = event.clientY;
      this.closeFolderSettings = true;
      this.showSettings = true;

      setTimeout(() => {
        this.closeFolderSettings = false;
      }, 10);
    },
    //Folder Methods
    async getData(params) {
      await Folder.get(params);
    },
    async openFolder(item) {
      await Folder.show(item.id, {
        onSuccess: () => {
          Folder.itemsBreadcrumbs.push({ text: item.name, id: item.id });
          this.$emit("setParent", item);
        },
      });
    },
    createFolder(data) {
      this.showSettings = false;
      Folder.post(data, this.createFolderState);
    },
    moveFolder(data) {
      this.$emit("moveFolder", data);
    },
    renameFolder(data) {
      Folder.put(data.item.id, data.data);
    },
    deleteFolder(id) {
      Folder.delete(id);
    },
    showCreateDialog() {
      this.createFolderState.state = true;
      this.showSettings = false;
    },

    //File Methods
    openAddFile() {
      this.addFileState.state = true;
    },
    fileChoosen(item) {
      this.$emit("fileChoosenWithId", item.id);
    },
    addFile(file) {
      this.closeAllTabs();
      let payload = {
        name: file.name.substring(0, file.name.indexOf(".")),
        file: file,
      };
      File.post(payload, this.addFileState);
    },
    moveFile(data) {
      this.$emit("moveFile", data);
    },
    renameFile(data) {
      File.put(data.item.id, data.data);
    },

    deleteFile(id) {
      File.delete(id);
    },
  },
  computed: {
    folders() {
      return Folder.data;
    },
    style() {
      return `left: ${this.mousePosX}px;  left: ${this.mousePosY}px;`;
    },
    indexLoader() {
      return Folder.getState.loading;
    },
    deleteLoader() {
      File.deleteState.loading;
    },
    addFolderLoader() {
      return Folder.postState.loading;
    },
    addFileLoader() {
      return File.postState.loading;
    },
    breadcrumbsItems() {
      return Folder.itemsBreadcrumbs;
    },
  },
  mounted() {
    if (Folder.data.children.length > 0 || Folder.data.files.length > 0) {
      return;
    } else {
      this.getData({ page: 1, parent_id: "null" });
      document.addEventListener("contextmenu", function(e) {
        e.preventDefault();
      });
    }
  },
  watch: {
    page() {
      this.getData({ page: this.page, parent_id: "null" });
    },
  },
};
</script>
<style lang="scss" scoped>
.folder-wrapper {
  height: 88vh !important;
  width: 100% !important;
  overflow-y: scroll;
}
.folder-wrapper::-webkit-scrollbar {
  display: none;
}
.settings {
  width: 200px;
  background-color: white !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  z-index: 3;
  .item:hover {
    background-color: #2196f3;
    color: white !important;
  }
  .delete:hover {
    background-color: rgba(255, 0, 0, 0.493);
    color: white !important;
    cursor: pointer;
  }
}
.v-breadcrumbs .primary--text {
  color: #007bff;
  text-decoration: none;
  font-weight: 800;
  cursor: pointer;
}
</style>
