<template>
  <div>
    <v-col class="ma-auto" lg="8">
      <v-stepper
        style="background-color: inherit !important; box-shadow: none; margin: auto;"
        alt-labels
        :value="value"
        class="mt-n8 mb-6"
      >
        <v-stepper-header>
          <v-stepper-step :complete="completed >= 1" step="1"> </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="completed >= 2" step="2"> </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="completed >= 3" step="3"> </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </v-col>
  </div>
</template>
<script>
export default {
  props: ["value", "complete"],

  computed: {
    completed() {
      return Number(this.complete);
    },
  },
};
</script>
