<template>
  <v-dialog
    @keydown.enter="$emit('deleteData', item.id)"
    v-model="dialog"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-title class="d-block">
        {{ $t("are you sure you want delete") }}
        <v-icon color="red">mdi-delete</v-icon>
        <h5 class="d-block red--text my-6">{{ name }} ?</h5>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('closePopup')">
          {{ $t("close") }}
        </v-btn>
        <v-btn
          :loading="loader"
          color="blue darken-1"
          text
          @click="$emit('deleteData', item.id)"
        >
          {{ $t("agree") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable*/
export default {
  props: ["item", "dialog", "loader"],
  computed: {
    name() {
      if (this.item) {
        return this.item.name;
      }
    },
  },
};
</script>
