import Swal from "sweetalert2";
export default class store {
  exerciseName = null;
  observable;
  data = [
    {
      fuck: "fuck",
      avatar: "sdfdsfasd",
      id: 1,
      name: "zakaria",
      category: {
        name: "test1",
        id: 1,
        sub: {
          name: "fuck1",
          id: "213",
        },
      },
      description: "sss",
    },
    {
      fuck: "fuck",
      avatar: "sdfdsfasd",
      id: 1,
      name: "zakaria",
      category: {
        name: "test1",
        id: 1,
        sub: {
          name: "fuck1",
          id: "213",
        },
      },
      description: "sss",
    },
    {
      fuck: "fuck",
      avatar: "sdfdsfasd",
      id: 1,
      name: "zakaria",
      category: {
        name: "test1",
        id: 1,
        sub: {
          name: "fuck1",
          id: "213",
        },
      },
      description: "sss",
    },
    {
      fuck: "fuck",
      avatar: "sdfdsfasd",
      id: 1,
      name: "zakaria",
      category: {
        name: "test1",
        id: 1,
        sub: {
          name: "fuck1",
          id: "213",
        },
      },
      description: "sss",
    },
    {
      fuck: "fuck",
      avatar: "sdfdsfasd",
      id: 1,
      name: "zakaria",
      category: {
        name: "test1",
        id: 1,
        sub: {
          name: "fuck1",
          id: "213",
        },
      },
      description: "sss",
    },
    {
      fuck: "fuck",
      avatar: "sdfdsfasd",
      id: 1,
      name: "zakaria",
      category: {
        name: "test1",
        id: 1,
        sub: {
          name: "fuck1",
          id: "213",
        },
      },
      description: "sss",
    },
    {
      fuck: "fuck",
      avatar: "sdfdsfasd",
      id: 1,
      name: "zakaria",
      category: {
        name: "test1",
        id: 1,
        sub: {
          name: "fuck1",
          id: "213",
        },
      },
      description: "sss",
    },
    {
      fuck: "fuck",
      avatar: "sdfdsfasd",

      id: 2,
      name: "zakaria",
      category: {
        name: "test2",
        id: 1,
        sub: {
          name: "fuck2",
          id: "2131",
        },
      },
      description: "kjvhidshvd",
    },
  ];
  action;
  removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    return arr;
  }
  action;
  getAutoComplete(event) {}
  action;
  deleteItem(id) {
    this.data.filter((item) => {
      if (item.id == id) {
        this.removeObjectWithId(Store.data, id);
      }
    });
  }
}
export const Store = new store();
