<template>
  <div class="text-center">
    <v-pagination
      v-model="data.current_page"
      :length="totalPages"
      total-visible="6"
      circle
      light
      next-icon="mdi-chevron-double-right"
      prev-icon="mdi-chevron-double-left"
      @input="updatePage"
    ></v-pagination>
  </div>
</template>
<script>
export default {
  props: ["data"],
  methods: {
    updatePage(value) {
      // this.$router.push({ query: { page: value } });
      this.$emit("updatePage", value);
    },
  },
  computed: {
    totalPages() {
      if (this.data.total > 0) {
        return Math.ceil(this.data.total / this.data.per_page);
      } else {
        return 1;
      }
    },
    page() {
      return this.$route.query.page;
    },
  },
  watch: {
    // page() {
    //   this.$emit("updatePage", this.$route.query.page);
    // },
  },
};
</script>
