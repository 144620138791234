<template>
  <div>
    <v-icon
      color="primary"
      @click="drawer = !drawer"
      x-large
      class="switch-icon"
      :class="{ active: drawer }"
      >mdi-arrow-left-circle</v-icon
    >

    <v-navigation-drawer
      class="primary"
      v-model="drawer"
      dark
      app
      v-bind:right="$i18n.locale == 'ar' ? true : false"
      :class="Auth.userType == 'teacher' ? 'teacherDrawer' : 'adminDrawer'"
    >
      <v-toolbar
        :color="Auth.userType == 'teacher' ? 'teal darken-4' : '#01579b'"
        height="80px"
        class="d-flex align-center"
        flat
      >
        <v-img
          width="70px"
          class="ma1"
          aria-rowcount="sss"
          height="50px"
          src="@/assets/Sama-Logo.svg"
        ></v-img>

        <v-toolbar-title class="mx-1" style="font-size: 12px;">{{
          $t("Sama Dashboard")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>

      <v-list rounded shaped>
        <div v-for="(item, index) in items" :key="index">
          <custom-list-item
            v-if="item.access.includes(userType)"
            :item="item"
            :active="activeItem"
            @activate="activateItem"
          ></custom-list-item>
        </div>
      </v-list>
      <template v-slot:append>
        <v-list-item style="border-radius: 25px;" @click="test">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ $t("logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
import CustomListItem from "./navigation-item.vue";
import { Auth } from "@/store/common/auth";
export default {
  components: {
    CustomListItem,
  },
  data() {
    return {
      Auth,
      drawer: true,
      items: [
        {
          title: "home",
          icon: "mdi-home",
          route: "/",
          access: ["admin", "teacher"],
        },
        {
          title: "exercise-table",
          icon: "mdi-table-check",
          route: "/Exercise/table",
          access: ["admin"],
        },
        {
          title: "age-groups",
          icon: "mdi-human",
          route: "/age-group",
          access: ["admin"],
        },
        {
          title: "skilles",
          icon: "mdi-arm-flex-outline",
          route: "/skilles",
          access: ["admin"],
        },

        {
          title: "plugin_library",
          icon: "mdi-google-drive",
          route: "/plugin-library",
          access: ["admin"],
        },
        {
          title: "users",
          icon: "mdi-account-group",
          route: "/users",
          access: ["admin"],
        },

        {
          title: "countries",
          icon: "mdi-map-marker-radius",
          route: "/countries",
          access: ["admin"],
        },
        {
          title: "schools",
          icon: "mdi-domain",
          route: "/schools",
          access: ["admin"],
        },
        {
          title: "grades",
          icon: "mdi-alpha-a-circle",
          route: "/edu_class",
          access: ["teacher"],
        },
        {
          title: "exams",
          icon: "mdi-note-multiple-outline",
          route: "/exams",
          access: ["teacher"],
        },
        {
          title: "solutions",
          icon: "mdi-notebook-check-outline",
          route: "/solutions",
          access: ["teacher"],
        },
      ],
      activeItem: null,
    };
  },
  methods: {
    test() {
      Auth.token = null;
      this.$router.push("/login");
    },
    activateItem(item) {
      this.activeItem = item;
    },
    changeLang() {
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "ar";
        this.$vuetify.rtl = true;
      } else if (this.$i18n.locale == "ar") {
        this.$i18n.locale = "en";
        this.$vuetify.rtl = false;
      }
    },
  },
  computed: {
    userType() {
      return Auth.userType;
    },
  },
};
</script>
<style lang="scss">
.v-navigation-drawer {
  .v-list-item {
    .v-icon {
      color: white !important;
    }

    &:hover {
      transition: all 0.5s ease;
      background-color: white !important;
      .v-list-item__content {
        .v-list-item__title {
          color: #01579b;
          font-weight: 700;
        }
      }
      .v-icon {
        color: #01579b !important;
      }
    }
  }

  .v-list-item--active {
    background-color: white;
    color: white;

    .v-list-item__content {
      .v-list-item__link {
        background-color: white;
        color: white;
      }

      .v-list-item__title {
        color: #01579b;
        font-weight: 800;
      }
    }
    .v-icon {
      color: #01579b !important;
    }
  }
}

.switch-icon {
  position: absolute;
  right: 0px;
  top: 20px;
  z-index: 999;
  &.active {
    transform: rotate(180deg);
  }
}
</style>
