<!-- eslint-disable -->
<template>
  <div>
    <table class="styled-table mt-n2">
      <thead>
        <tr class="text-center primary">
          <th v-for="(value, key) in headers" :key="key">
            <span v-if="value.name"> {{ $t(value.name) }}</span>
            <slot v-else :name="'header:' + value.slot"></slot>
          </th>
          <th><slot :name="'header'"></slot></th>
          <th v-if="actionAble">{{ $t("action") }}</th>
          <th v-if="selectable">
            <input type="checkbox" @click="selectAll" v-model="select_all" />
          </th>
          <th v-if="moveable">
            <v-spacer></v-spacer>
          </th>
          <th v-if="expandable">
            <v-spacer></v-spacer>
          </th>
        </tr>
      </thead>
      <v-fade-transition mode="out-in">
        <tbody v-if="!loader">
          <template v-for="(row, index) in data">
            <v-fade-transition
              :key="index"
              :class="{ variant: checkVariant(index) }"
              mode="out-in"
            >
              <template>
                <tr class="text-center" style="cursor: pointer;">
                  <td v-for="(value, key) in headers" :key="key">
                    <template v-if="value.slot">
                      <slot
                        :name="value.slot"
                        :item="getValue(row, value.keys)"
                      ></slot>
                    </template>

                    <template v-else>
                      <span v-if="!Array.isArray(getValue(row, value.keys))">
                        {{
                          isLocalized
                            ? $t(getValue(row, value.keys))
                            : getValue(row, value.keys)
                        }}
                      </span>
                      <template v-else>
                        <span
                          v-for="(item, index) in getValue(row, value.keys)"
                          :key="index"
                        >
                          ,{{ getValue(item, value.arrayKey) }}
                        </span>
                      </template>
                    </template>

                    <!-- <v-icon
                  :color="value.icon.color"
                  :size="value.icon.size"
                  v-if="value.icon"
                  >mdi-{{ value.icon.name }}</v-icon
                > -->
                  </td>
                  <td>
                    <span><slot :name="'value'"></slot></span>
                  </td>
                  <td v-if="actionAble">
                    <v-icon
                      @click="edit(row)"
                      color="primary"
                      class=" ma-1"
                      v-if="editable"
                      >mdi-square-edit-outline
                    </v-icon>
                    <v-icon
                      @click="copy(row)"
                      v-if="copyable"
                      color="primary"
                      class=" autha-1"
                      >mdi-content-copy
                    </v-icon>
                    <v-icon
                      @click="show(row)"
                      v-if="showable"
                      color="primary"
                      class=" ma-1"
                      >mdi-eye
                    </v-icon>
                    <v-icon
                      size="30"
                      @click="deleteItem(row)"
                      v-if="deleteable"
                      color="primary"
                      class=" ma-1"
                      >mdi-delete-outline
                    </v-icon>
                    <v-icon
                      @click="select(row)"
                      v-if="selectable && type"
                      :value="row.selected"
                      color="success"
                      >mdi-check-bold</v-icon
                    >
                    <v-icon
                      @click="select(row)"
                      v-if="selectable && !type"
                      :value="row.selected"
                      color="red"
                      >mdi-close-thick</v-icon
                    >
                  </td>
                  <td v-if="moveable">
                    <slot :name="'move-btn'" :data="row"></slot>
                  </td>
                  <td @click="expandRow(row.id)" v-if="expandable">
                    <slot
                      :name="'expand-icon'"
                      :data="{ item: row, expand: expand }"
                    ></slot>
                  </td>
                </tr>
              </template>
            </v-fade-transition>
            <v-expand-transition>
              <tr v-if="expand == row.id">
                <td :colspan="12">
                  <slot :name="'expand'" :item="row"></slot>
                </td>
              </tr>
            </v-expand-transition>
          </template>
        </tbody>
      </v-fade-transition>
    </table>
    <div
      v-if="loader"
      class="mt-n6 d-flex justify-center align-center"
      style="width: 100%; height: 100px; background-color: whitesmoke;"
    >
      <pulse-loader
        :loading="true"
        :color="$vuetify.theme.themes.light.primary"
        size="20px"
      />
    </div>
    <pagination @updatePage="$emit('updatePage', $event)" :data="meta" />
  </div>
</template>
<script>
export default {
  props: {
    headers: {
      type: Object,
    },
    data: {
      type: Array,
    },
    editable: {
      type: Function,
    },
    deleteable: {
      type: Function,
    },
    copyable: {
      type: Function,
    },
    showable: {
      type: Function,
    },
    selectable: {
      type: Function,
    },
    selectMulty: {
      type: Function,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    moveable: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Boolean,
      default: true,
    },
    meta: {
      type: Object,
    },
    isLocalized: {
      type: Boolean,
      default: true,
    },
    selectedState: {},
    Store: {},
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      click: false,
      select_all: false,
      expand: -1,
    };
  },

  methods: {
    getValue(obj, keys) {
      let value = obj;
      for (let index in keys) {
        value = value[keys[index]];
        if (keys[index] == "created_at" && value) {
          value = value.substring(0, 10);
        }
      }

      return value;
    },
    expandRow(id) {
      if (id == this.expand) this.expand = -1;
      else this.expand = id;
    },
    edit(obj) {
      this.editable(obj);
    },
    deleteItem(obj) {
      this.deleteable(obj);
    },
    copy(obj) {
      this.copyable(obj);
    },
    show(obj) {
      this.showable(obj);
    },
    select(obj) {
      this.selectable(obj);
    },
    selectmulty() {
      // this.selectMulty(Lesson.selected);
    },
    selectAll() {
      this.Store.selected = [];
      if (!this.select_all) {
        this.data.forEach((item) => {
          this.Store.selected.push(item.id);
        });
      }
      this.$emit("selectAll", this.selected);
    },
    checkVariant(index) {
      return index % 2 == 1;
    },
    isSelectedAll() {
      return (
        this.data.length == this.Store.selected.length && this.data.length != 0
      );
    },
    selected() {
      return this.Store.selected;
    },
  },
  computed: {
    actionAble() {
      return this.editable || this.deleteable || this.copyable || this.showable;
    },
  },
  watch: {
    "Store.selected": function() {
      if (
        this.Store.selected.length == this.data.length &&
        this.data.length != 0
      ) {
        this.select_all = true;
      } else {
        this.select_all = false;
      }
    },
  },
};
</script>
<style scoped>
@import "../../../assets/scss/md/tables/tabels.scss";
.variant {
  background-color: whitesmoke;
}
</style>

Powered by Gitea Version: 1.15.7 Page: 136ms Template: 16ms Licenses API Website
Go1.16.10
