// Pages
import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import isAdmin from "@/middleware/isAdmin";

let generalRoutes = {
  path: "",
  component: DashboardLayout,
  name: "general",
  children: [
    {
      path: "/",
      name: "home",
      component: () => import("@/pages/Dashboard/Dashboard.vue"),
      meta: { middleware: [auth] },
    },
    {
      path: "/plugin-library",
      name: "plugin_library",
      component: () => import("@/pages/Dashboard/Pages/libraryPlugin.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/age-group",
      name: "age-groups",
      component: () => import("@/pages/Dashboard/age-group/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/skilles",
      name: "skilles",
      component: () => import("@/pages/Dashboard/skilles/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/countries",
      name: "countries",
      component: () => import("@/pages/Dashboard/countries/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/semesters",
      name: "semesters",
      component: () => import("@/pages/Dashboard/semester/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/educational_level",
      name: "educational_level",
      component: () => import("@/pages/Dashboard/educational_level/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/grade",
      name: "grades",
      component: () => import("@/pages/Dashboard/grade/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/subject",
      name: "subjects",
      component: () => import("@/pages/Dashboard/subjects/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/station",
      name: "stations",
      component: () => import("@/pages/Dashboard/stations/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/schools",
      name: "schools",
      component: () => import("../pages/Dashboard/schools/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/school/:type/:id",
      name: "",
      component: () => import("@/pages/Dashboard/schools/show.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
  ],
};

let teacherRoutes = {
  path: "",
  component: DashboardLayout,
  name: "teacher",
  meta: { middleware: auth },
  children: [
    {
      path: "/edu_class",
      name: "grades",
      component: () => import("@/pages/teacher/edu_classes/index.vue"),
      meta: { middleware: auth },
    },
    {
      path: "/edu_class/:id",
      name: "",
      component: () => import("@/pages/teacher/edu_classes/show.vue"),
      meta: { middleware: auth },
    },
    {
      path: "/student_exams/:id",
      name: "",
      component: () =>
        import("@/pages/teacher/edu_classes/students/showStudentExams.vue"),
      meta: { middleware: auth },
    },
    {
      path: "/exams",
      name: "exams",
      component: () => import("@/pages/teacher/exams/index.vue"),
      meta: { middleware: auth },
    },
    {
      path: "/exam/:id",
      name: "",
      component: () => import("@/pages/teacher/exams/show.vue"),
      meta: { middleware: auth },
    },
    {
      path: "/solutions",
      name: "solutions",
      component: () => import("@/pages/teacher/solutions/index.vue"),
      meta: { middleware: auth },
    },
    {
      path: "/solution/:id",
      name: "",
      component: () => import("@/pages/teacher/solutions/show.vue"),
      meta: { middleware: auth },
    },
    {
      path: "/station/:id",
      name: "",
      component: () => import("@/pages/teacher/edu_classes/station/show.vue"),
      meta: { middleware: auth },
    },
  ],
};

let exerciseRoutes = {
  path: "",
  component: DashboardLayout,
  name: "Components",
  children: [
    {
      path: "/Exercise/table",
      name: "exercise-table",
      component: () => import("@/pages/Dashboard/Exercise/RegularTables.vue"),
      meta: { middleware: [auth, isAdmin] },
    },

    {
      path: "/Exercise/add-exercise",
      name: "Add Exercise",
      component: () =>
        import("@/pages/Dashboard/Exercise/add-exercise/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/Exercise/edit-exercise/:id",
      name: "Edit Exercise",
      component: () =>
        import("@/pages/Dashboard/Exercise/edit-exercise/index.vue"),
      meta: { middleware: auth },
    },
    {
      path: "/Exercise/copy-exercise/:id",
      name: "Copy Exercise",
      component: () =>
        import("@/pages/Dashboard/Exercise/copy-exercise/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "/Exercise/end-exercise",
      name: "Add Exercise",
      component: () => import("@/pages/Dashboard/Exercise/endExercise.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
  ],
};
let users = {
  path: "",
  component: DashboardLayout,
  name: "users",
  children: [
    {
      path: "teachers",
      name: "teachers",
      component: () => import("@/pages/Dashboard/users/teachers/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "managers",
      name: "managers",
      component: () => import("@/pages/Dashboard/users/manager/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "students",
      name: "students",
      component: () => import("@/pages/Dashboard/users/students/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
    {
      path: "users",
      name: "users",
      component: () => import("@/pages/Dashboard/users/users/index.vue"),
      meta: { middleware: [auth, isAdmin] },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/pages/Dashboard/Pages/Login.vue"),
      meta: { middleware: guest },
    },
  ],
};

const routes = [generalRoutes, teacherRoutes, exerciseRoutes, authPages, users];

export default routes;
