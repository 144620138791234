<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-card
        @click.right.prevent.stop="showFileList"
        @keydown.delete="deleteItem = true"
        @click="fileChoosen"
        v-click-outside="colseSet"
        v-click-outside-right="colseSet"
        v-bind="attrs"
        v-on="on"
        width="100%"
        height="200px"
      >
        <v-card-actions style="height: 20%;">
          <v-col class="d-flex pa-0" lg="10" md="10" sm="10" cols="10">
            <v-icon color="primary">mdi-{{ getIcon(item.type) }}</v-icon>
            <span class="ma-2 title-2">{{ shortName(item.name) }}</span>
          </v-col>
          <v-col class="d-flex justify-center" lg="2">
            <v-btn @click.stop="showFileList" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-col>
        </v-card-actions>
        <v-divider></v-divider>
        <div
          style=" height: 80%;"
          v-if="!downloadStatus"
          class="d-flex justify-center align-center"
        >
          <drive-loader v-if="myState.loading" />

          <v-icon v-else @click="showFile" color="primary" size="100"
            >mdi-cloud-download-outline</v-icon
          >
        </div>

        <div v-else style="height: 100%;">
          <video
            v-if="item.type == 'video'"
            class=" white--text align-end  video"
            autoplay
            muted
            controls
          >
            <source :src="mySrc" type="video/mp4" />
          </video>
          <div v-else-if="item.type == 'audio'" class="audio">
            <v-icon color="primary" size="100" class="icon"
              >mdi-headphones</v-icon
            >
            <audio style="height: 50px;width: 100%;" controls>
              <source :src="mySrc" type="audio/mpeg" />
            </audio>
          </div>
          <v-img
            v-else-if="(item.type == 'image') & (item.mimetype != 'svg+xml')"
            :src="mySrc"
            class="white--text align-end image"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.7)"
          >
          </v-img>
          <div
            class="svgImg"
            v-else-if="(item.type == 'image') & (item.mimetype == 'svg+xml')"
            v-html="mySrc"
          ></div>
          <div
            style="height: 80%;"
            class="d-flex align-center justify-center"
            v-else
          >
            <v-icon color="primary" size="100">mdi-file-document</v-icon>
          </div>
        </div>
        <v-expand-transition>
          <v-list v-if="showSettings" class="text-center settings">
            <v-list-item
              class="item"
              v-for="(item, index) in items"
              :key="index"
              @click.stop.prevent="item.action"
            >
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop.prevent="deleteItem = true" class="delete">
              <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-expand-transition>
      </v-card>
      <delete-item
        @closePopup="deleteItem = false"
        @deleteData="deleteFile($event)"
        :dialog="deleteItem"
        :item="item"
        :loader="deleteLoader"
      />
      <re-name
        v-if="rename"
        @closePopup="rename = false"
        :item="item"
        :dialog="rename"
        @submit="renameFile($event)"
      />
    </template>
    <p class="ma-2">{{ $t("name") }} : {{ item.name }}</p>
    <p class="ma-2">
      {{ $t("created_at") }} : {{ item.created_at.substring(0, 10) }}
    </p>
    <p class="ma-2">{{ $t("type") }} : {{ item.mimetype }}</p>
    <p class="ma-2">{{ $t("size") }} : {{ item.size / 1000 }} MB</p>
  </v-tooltip>
</template>
<script>
import { File } from "@/store/file-system/file";
import reName from "./dialog.vue";
import { State } from "@/store/state";
import driveLoader from "../driveLoader.vue";
export default {
  props: ["item", "close", "accept", "fromBase"],
  components: {
    reName,
    driveLoader,
  },
  directives: {
    "click-outside-right": {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression]();
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  data() {
    return {
      File,
      State,
      myState: {},
      deleteItem: false,
      showSettings: false,
      rename: false,
      downloadStatus: false,

      items: [
        { title: "move", action: this.move },
        { title: "rename", action: this.reName },
        { title: "download", action: this.download },
      ],
    };
  },
  methods: {
    fileChoosen() {
      // if (this.fromBase) return;
      // if (this.item.type != this.typeMapper(this.accept)) {
      //   this.$toast(
      //     this.$t("file you chosen not compact with exercise type"),
      //     { timeout: 4000, rtl: true },
      //     "error"
      //   );
      //   return;
      // }
      this.$emit("fileChoosenWithId", this.item);
    },
    async showFile() {
      this.myState = new State();
      await File.show(this.myState, this.item.id);
      this.downloadStatus = true;
    },
    colseSet() {
      this.showSettings = false;
    },
    move() {
      this.showSettings = false;
      this.$emit("moveFile", this.item);
    },
    reName() {
      this.rename = true;
      this.showSettings = false;
    },
    async download() {
      if (this.mySrc) {
        this.myState = new State();
        await File.show(this.myState, this.item.id);
        this.downloadStatus = true;
        const link = document.createElement("a");
        link.href = this.mySrc;
        link.download = this.item.name + "." + this.item.mimetype;
        link.click();
      }
    },
    renameFile(event) {
      this.rename = false;
      this.$emit("renameFile", { item: this.item, data: event });
    },

    shortName(name) {
      if (name.length > 15) {
        return name.slice(0, 15) + " ... ";
      } else {
        return name;
      }
    },
    showFileList() {
      this.showSettings = !this.showSettings;
      this.$emit("rightClick");
    },
    deleteFile() {
      this.$emit("deleteFile", this.item.id);
    },
    getIcon(icon) {
      switch (icon) {
        case "image":
          return "image";
          break;
        case "audio":
          return "headphones";
          break;
        case "video":
          return "video";
          break;
        default:
          return "file-document";
          break;
      }
    },
    typeMapper(type) {
      const exercise = new Map([
        ["audio/*", "audio"],
        ["image/*", "image"],
        ["video/*", "video"],
      ]);
      return exercise.get(type);
    },
  },
  computed: {
    imageType() {
      return this.item.type == "image";
    },
    mySrc() {
      if (this.item.mimetype == "svg+xml") {
        let decoder = new TextDecoder("utf-8");
        const svgString = decoder.decode(this.myState.value);
        return svgString;
      }

      const blob = new Blob([this.myState.value], {
        type: this.item.type / this.item.mimetype,
      }); //
      const imgUrl = URL.createObjectURL(blob);

      return imgUrl;
    },
    deleteLoader() {
      return File.deleteState.loading;
    },
  },
  mounted() {},
  watch: {
    close: function(value) {
      if (value) {
        this.showSettings = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.image {
  height: 77%;
  width: 96%;
  margin: auto !important;
  object-fit: fill;
}
.video {
  height: 77%;
  width: 100%;
  margin: auto !important;
  object-fit: cover;
}
.audio {
  height: 80%;
  display: flex;
  align-items: flex-end;
  position: relative;
  .icon {
    z-index: 0;
    bottom: 10%;
    position: absolute;
    height: 100% !important;
    width: 100% !important;
  }
}
.svgImg {
  height: 20%;
  img {
    transform: scale(0.5);
    object-fit: fill;
  }
}
span {
  font-weight: 600;
  line-height: 1;
  width: 100%;
}
.nullType {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.settings {
  position: absolute;
  width: 60%;
  right: 0;
  left: 0;

  font-weight: 600;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  background-color: white !important;
  margin: auto;
  margin-top: 5px;
  z-index: 1;
  .item:hover {
    background-color: #2196f3;
    color: white !important;
  }
  .delete:hover {
    background-color: rgba(255, 0, 0, 0.493);
    color: white !important;
    cursor: pointer;
  }
}
</style>
