<template>
  <v-autocomplete
    @change="setItem"
    ref="autocomplete"
    :key="autocompleteKey"
    v-model="item"
    :items="items"
    :item-text="isObject ? itemText : null"
    :item-value="isObject ? itemValue : null"
    outlined
    :menu-props="{ closeOnClick: true }"
    :loading="loading"
    :rounded="rounded"
    :multiple="multiple"
  >
    <template #label>
      <span>{{ $t(label) }}</span
      ><span v-if="required"> * </span>
    </template>
    <template v-if="!stopIntersect" v-slot:append-item>
      <div
        v-intersect="onIntersect"
        class="pa-4 teal--text d-flex justify-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
    <template #no-data>
      <div class="text-center d-flex justify-center align-center">
        <v-icon>mdi-database-off-outline</v-icon>
        <span class="text-center primary--text text-h6">{{
          $t("No Data")
        }}</span>
      </div>
    </template>
    <template v-if="itemTextSecond" #item="{ item }">
      {{ item[itemText] }} {{ item[itemTextSecond] }}
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    data: {},
    Store: {},
    paramas: {},
    label: {},
    isObject: {
      type: Boolean,
    },
    clearAutocomplete: {},
    itemText: {
      default: "name",
    },
    itemTextSecond: {},
    itemValue: {
      default: "id",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      default: [],
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: null,
      items: [],
      page: 0,
      filterData: {},
      autocompleteKey: 0,
    };
  },
  methods: {
    setItem() {
      this.$emit("getItem", this.item);
      // this.item = null;
      this.page = 0;
    },
    async onIntersect() {
      this.page += 1;
      Object.assign(this.filterData, { page: this.page });
      Object.assign(this.filterData, this.paramas);
      await this.Store.get(this.filterData);
      this.items = this.items.concat(this.StoreItems);
    },
    reset() {
      this.item = null;
    },
  },
  computed: {
    StoreItems() {
      return this.Store.tableData.data;
    },
    stopIntersect() {
      return this.Store.tableData.data == [];
    },
    loading() {
      return this.Store.getState.loading;
    },
  },
  watch: {
    clearAutocomplete() {
      this.item = ""; // Clear the autocomplete
      this.$emit("clear-autocomplete-done"); // Emit an event to notify the parent
      this.autocompleteKey += 1; // Increment the key value to trigger re-render
      this.$nextTick(() => {
        this.$refs.autocomplete.reset(); // Reset the autocomplete component
      });
    },
  },
  created() {
    this.onIntersect();
    this.item = this.data;
  },
};
</script>
