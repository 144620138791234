<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-card
        v-click-outside="closeSet"
        v-click-outside-right="closeSet"
        @keydown.delete="$emit('deleteFolder', item.id)"
        v-bind="attrs"
        v-on="on"
        @click.right.prevent.stop="showFolderSettings"
        class="base"
      >
        <v-card rounded class="d-flex  align-center folder" @click="openFolder">
          <v-col
            class="d-flex justify-start align-center"
            lg="11"
            md="11"
            sm="10"
            cols="8"
          >
            <v-icon size="30">mdi-folder</v-icon>
            <span class="ma-2 body-1">{{ shortName(item.name) }}</span>
          </v-col>
          <v-col class="d-flex justify-end" lg="1" md="1" sm="2" cols="4">
            <v-icon @click.stop="showFolderSettings" size="30"
              >mdi-dots-vertical</v-icon
            >
          </v-col>
        </v-card>

        <v-expand-transition>
          <v-list
            @click:outside="showFolderSettings"
            v-if="showSettings"
            class="text-center settings"
          >
            <v-list-item
              class="item"
              v-for="(item, index) in items"
              :key="index"
              @click="item.action"
            >
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteItem = true" class="delete">
              <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-expand-transition>
        <re-name
          v-if="rename"
          @closePopup="rename = false"
          :item="item"
          :dialog="rename"
          @submit="renameFolder($event)"
        />
        <delete-item
          @closePopup="deleteItem = false"
          @deleteData="deleteFolder($event)"
          :dialog="deleteItem"
          :item="item"
        ></delete-item>
      </v-card>
    </template>
    <p class="ma-2">{{ $t("name") }} : {{ item.name }}</p>
    <p class="ma-2">
      {{ $t("created_at") }} : {{ item.created_at.substring(0, 10) }}
    </p>
  </v-tooltip>
</template>
<script>
import reName from "./dialog.vue";
export default {
  props: ["item", "close"],
  components: {
    reName,
  },
  directives: {
    "click-outside-right": {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression]();
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  data() {
    return {
      items: [
        { title: "move", action: this.enableMove },
        { title: "rename", action: this.openReanmeDialog },
      ],
      showSettings: false,
      rename: false,
      deleteItem: false,
    };
  },
  methods: {
    closeSet() {
      this.showSettings = false;
    },
    shortName(name) {
      if (name.length > 15) {
        return name.slice(0, 12) + " ... ";
      } else {
        return name;
      }
    },
    openFolder() {
      this.$emit("openFolder", this.item);
    },
    enableMove() {
      this.$emit("moveFolder", this.item);
    },
    openReanmeDialog() {
      this.rename = true;
      this.showSettings = false;
    },
    renameFolder(event) {
      this.rename = false;
      this.$emit("renameFolder", { item: this.item, data: event });
    },
    deleteFolder() {
      this.showSettings = false;
      this.$emit("deleteFolder", this.item.id);
    },
    showFolderSettings() {
      this.showSettings = !this.showSettings;
      this.$emit("rightClick");
    },
  },

  watch: {
    close: function(value) {
      if (value) {
        this.showSettings = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.folder {
  background-color: rgb(128, 128, 128, 0.4) !important;
  box-shadow: none !important;
  position: relative;
  widows: 100%;
  height: 100%;
  z-index: 0;
  span {
    font-size: 0.8vw;
    font-weight: 600;
    line-height: normal;
  }
}
.settings {
  position: absolute;
  width: 60%;
  right: 0;
  left: 0;

  font-weight: 600;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  background-color: white !important;
  margin: auto;
  margin-top: 5px;
  z-index: 1;
  .item:hover {
    background-color: #2196f3;
    color: white !important;
  }
  .delete:hover {
    background-color: rgba(255, 0, 0, 0.493);
    color: white !important;
    cursor: pointer;
  }
}
// .settingsRtl {
//   left: 0;
// }
</style>
