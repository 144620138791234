import Vue from "vue";
import "vue-toastification/dist/index.css";
export default function toast(
  message,
  options = {
    position: "top-right",
    timeout: 0,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: true,
  },
  status = "success"
) {
  switch (status) {
    case "error":
      Vue.$toast.error(message, options);
      break;
    case "warning":
      Vue.$toast.warning(message, options);
      break;
    case "info":
      Vue.$toast.info(message, options);
      break;
    default:
      Vue.$toast.success(message, options);
  }
}
