var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeAllTabs),expression:"closeAllTabs"}],staticClass:"folder-wrapper d-block",on:{"click":_vm.closeAllTabs,"contextmenu":function($event){return _vm.mouseMove($event)}}},[_c('v-breadcrumbs',{staticClass:"mb-6",attrs:{"items":_vm.breadcrumbsItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{on:{"click":function($event){return _vm.moveByBreadCrumbs(item)}}},[_c('span',{class:{
              'primary--text':
                item !== _vm.breadcrumbsItems[_vm.breadcrumbsItems.length - 1],
              disable: item === _vm.breadcrumbsItems[_vm.breadcrumbsItems.length - 1],
            }},[_vm._v(" "+_vm._s(_vm.$t(item.text).toUpperCase()))])])]}},{key:"divider",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-left")])]},proxy:true}])}),(!_vm.indexLoader)?_c('v-row',_vm._l((_vm.folders.children),function(item,i){return _c('v-col',{key:i,staticStyle:{"height":"100%"},attrs:{"lg":_vm.lg,"md":_vm.md,"sm":_vm.sm,"cols":_vm.cols}},[_c('folder',{attrs:{"item":item,"close":_vm.closeFolderSettings},on:{"openFolder":function($event){return _vm.openFolder($event)},"moveFolder":function($event){return _vm.moveFolder($event)},"renameFolder":function($event){return _vm.renameFolder($event)},"deleteFolder":function($event){return _vm.deleteFolder($event)},"rightClick":function($event){_vm.showSettings = false}}})],1)}),1):_vm._e(),(!_vm.indexLoader)?_c('v-row',[_vm._l((_vm.folders.files),function(item,i){return [(true)?_c('v-col',{key:i,attrs:{"lg":_vm.lg,"md":_vm.md,"sm":_vm.sm,"cols":_vm.cols},on:{"contextmenu":function($event){_vm.showSettings = false}}},[_c('file',{attrs:{"item":item,"fromBase":_vm.fromBase,"accept":_vm.accept,"close":_vm.closeFolderSettings,"deleteLoader":_vm.deleteLoader},on:{"fileChoosenWithId":function($event){return _vm.fileChoosen($event)},"moveFile":function($event){return _vm.moveFile($event)},"renameFile":function($event){return _vm.renameFile($event)},"deleteFile":function($event){return _vm.deleteFile($event)},"rightClick":function($event){_vm.showSettings = false}}})],1):_vm._e()]})],2):_vm._e(),(_vm.indexLoader)?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"100%"}},[_c('drive-loader')],1):_vm._e(),(
        _vm.Folder.data.children.length == 0 &&
          _vm.Folder.data.files.length == 0 &&
          !_vm.indexLoader
      )?_c('div',{staticClass:" d-flex align-center justify-center folder-wrapper",on:{"contextmenu":function($event){return _vm.mouseMove($event)},"click":_vm.closeAllTabs}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"1000%"}},[_vm._v("mdi-image-off")]),_c('p',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$t("no data")))])],1)]):_vm._e()],1),_c('v-list',{staticStyle:{"position":"absolute"},style:([{ top: _vm.mousePosY + 'px' }, { left: _vm.mousePosX + 'px' }])},[_c('v-expand-transition',[(_vm.showSettings)?_c('v-list',{staticClass:" settings"},[_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,staticClass:"item",on:{"click":item.action}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),(_vm.parent)?_c('v-list-item',{staticClass:"item",on:{"click":_vm.openAddFile}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("add_new_file")))])],1):_vm._e()],2):_vm._e()],1)],1),(_vm.createFolderState.state)?_c('create-folder',{attrs:{"loading":_vm.addFolderLoader,"dialog":_vm.createFolderState.state},on:{"closePopup":function($event){_vm.createFolderState.state = false},"submit":function($event){return _vm.createFolder($event)}}}):_vm._e(),_c('create-file',{attrs:{"status":_vm.addFileState.state,"enablePlugin":false,"loader":_vm.addFileLoader},on:{"fileChoosen":function($event){return _vm.addFile($event)},"closePopup":function($event){_vm.addFileState.state = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }