<template>
  <v-card
    class="ma-auto mt-6 mb-6"
    width="40%"
    height="400px"
    v-if="src != null"
  >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="cancelEnable" icon>
        <v-icon @click="resetInput">mdi-close-circle</v-icon>
      </v-btn>
    </v-card-actions>
    <v-fade-transition v-if="type == 'video'" mode="out-in">
      <video class="video" autoplay muted controls>
        <source :src="src" type="video/mp4" />
      </video>
    </v-fade-transition>
    <v-fade-transition v-else mode="out-in">
      <img :src="src" alt="" />
    </v-fade-transition>
  </v-card>
  <v-card
    class="d-flex align-center justify-center ma-auto"
    width="40%"
    height="400px"
    @click="dialog = true"
    v-else
  >
    <v-progress-circular
      v-if="loader"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <div v-else class="text-center">
      <v-icon size="100">mdi-image-off-outline</v-icon>
      <h5 v-if="type == 'video'">{{ $t("No_video_added") }}</h5>
      <h5 v-else>{{ $t("No_image_added") }}</h5>
    </div>
    <add-popup
      @fileChoosen="setsrc($event)"
      @fileChoosenId="setSrcWithId($event)"
      @closePopup="dialog = false"
      :status="dialog"
      :reset="src"
      :accept="detectType"
      :enablePlugin="true"
    ></add-popup>
  </v-card>
</template>
<script>
//TODO:modify hight of image and video

import addPopup from "../base/addPopup.vue";
import { File } from "@/store/file-system/file";
import { State } from "@/store/state";
export default {
  components: {
    addPopup,
  },
  props: {
    data: {},
    fileServer: {},
    cancelEnable: {
      type: Boolean,
      default: true,
    },
    type: {},
  },
  data() {
    return {
      File,
      State,
      dialog: false,
      src: null,
      myState: null,
    };
  },
  methods: {
    async setSrcWithId(id) {
      this.myState = new State();
      await File.show(this.myState, id, this.fileServer);
      const blob = new Blob([this.myState.value], {});
      const imgUrl = URL.createObjectURL(blob);
      this.src = imgUrl;
      this.$emit("getVideoId", id);
    },
    setsrc(file) {
      this.dialog = false;
      this.src = file;
      this.$emit("fileLoaded", file);
    },
    resetInput() {
      this.src = null;
      this.$emit("getVideoId", null);
    },
  },
  computed: {
    srcVideo() {
      let data = URL.createObjectURL(this.src);
      return data;
    },
    detectType() {
      if (this.type == "image") {
        return "image/*";
      } else if (this.type == "video") {
        return "video/*";
      } else {
        return "";
      }
    },
    loader() {
      if (this.data) {
        return this.myState.loading;
      }
      return false;
    },
  },
  created() {
    if (this.data) {
      this.setSrcWithId(this.data);
    }
  },
};
</script>
<style lang="scss" scoped>
.video {
  width: 100% !important;
  height: 88% !important;
  margin-bottom: 50px;
}
img {
  width: 100% !important;
  height: 88% !important;
  margin-bottom: 50px;
}
</style>
