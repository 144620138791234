import { State } from "../state";
import { Request } from "@/network/request";
import router from "../../router/index";
import i18n from "../../i18n";
import toast from "../../plugins/toast";
export default class file {
  getState = new State();
  postState = new State();
  showState = new State();
  putState = new State();
  deleteState = new State();
  itemsBreadcrumbs = [
    {
      text: i18n.t("plugin_library"),
      disabled: false,
      href: "breadcrumbs_dashboard",
    },
  ];
  data = {
    children: [],
    files: [],
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };

  async get(params) {
    this.itemsBreadcrumbs = [
      {
        text: i18n.t("plugin_library"),
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
    ];
    let request = new Request({
      method: "get",
      endpoint: `/folder`,
      params: params,
    });

    await this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.data = {
            children: [],
            files: [],
          };
          this.data.meta = value.meta;
          this.data.children = value.folders;
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  post(data, state) {
    let check = this.data.children.filter((item) => item.name == data.name);
    if (check.length > 0) {
      toast(
        i18n.t("folder name already exists"),
        { timeout: 4000, rtl: true },
        "error"
      );
      return;
    }
    let request = new Request({
      method: "post",
      endpoint: `/folder`,
      data: data,
    });
    if ("parent" in this.data) {
      Object.assign(data, { parent_id: this.data.id });
    } else {
      Object.assign(data, { parent_id: null });
    }

    this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          state.state = false;
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
          if ("parent" in this.data) {
            this.show(this.data.id);
          } else {
            this.get({ page: 1, parent_id: "null" });
          }
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async show(id, { onSuccess }) {
    let request = new Request({
      method: "get",
      endpoint: `/folder/${id}`,
    });

    await this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.data = value.data;
          if (onSuccess) onSuccess();
        },
        onError: (error) => {
          // toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  put(id, data) {
    let check = this.data.children.filter((item) => item.name == data.name);
    if (check.length > 0) {
      toast(
        i18n.t("folder name already exists"),
        { timeout: 4000, rtl: true },
        "error"
      );
      return;
    }
    let request = new Request({
      method: "put",
      endpoint: `/folder/${id}`,
      data: data,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(i18n.t("edit success"), { timeout: 4000, rtl: true }, "sucess");
          if ("parent" in this.data) {
            this.show(this.data.id);
          } else {
            this.get({ page: 1, parent_id: "null" });
          }
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  delete(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/folder/${id}`,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
          if ("parent" in this.data) {
            this.show(this.data.id);
          } else {
            this.get({ page: 1, parent_id: "null" });
          }
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}
export const Folder = new file();
