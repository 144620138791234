<template>
  <div>
    <input
      type="file"
      ref="inputField"
      style="display: none"
      :accept="accept"
      @input="getFile"
    />
    <v-card
      elevation="0"
      class="image-input__input-wrapper"
      :style="{ height: imgHeight + 'px', width: imgWidth + 'px' }"
    >
      <!-- <v-card-title
        style="padding: 8px"
        class="d-flex justify-space-between"
        v-if="!emptyInputField"
      >
        <v-icon @click="removeImageFile" v-if="false">mdi-close</v-icon>
      </v-card-title> -->
      <v-card-text
        @click="handleImage"
        :class="{ 'image-input__empty-input': emptyInputField }"
      >
        <div
          v-if="true"
          class="d-flex flex-column justify-center text-center align-center"
          :class="{ 'empty-input__absolute-info': emptyInputField }"
        >
          <v-icon size="100">mdi-plus</v-icon>
          <h6>{{ placeHolder }}</h6>
        </div>
      </v-card-text>
      <!-- <v-card-actions> </v-card-actions> -->
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: null,
      emptyInputField: false,
      srcImagePreview: null,
      resetImageInput: this.resetImage,
    };
  },
  props: [
    "placeHolder",
    "srcImage",
    "imageFile",
    "imgWidth",
    "imgHeight",
    "createFeature",
    "resetImage",
    "type",
    "accept",
  ],
  watch: {
    resetImage(v) {
      if (v) {
        this.removeImageFile();
      }
    },
  },
  methods: {
    previewImage() {
      if (this.imgUrl) {
        window.open(this.imgUrl);
      }
      if (this.srcImagePreview) {
        window.open(this.srcImagePreview);
      }
    },
    removeImageFile() {
      if (this.srcImagePreview) {
        this.srcImagePreview = null;
      }
      this.emptyInputField = true;
      this.imgUrl = null;
      // this.img = null;
      this.$refs.inputField.value = null;
      this.$emit("fileRemoved");
    },
    getFile(file) {
      if (file.target.files[0] == undefined) {
        return;
      }
      if (!this.createFeature) {
        this.srcImagePreview = URL.createObjectURL(file.target.files[0]);
      } else {
        this.imgUrl = URL.createObjectURL(file.target.files[0]);
      }
      this.emptyInputField = false;
      this.$emit("fileValue", file.target.files[0]);
      this.removeImageFile();
    },
    handleImage() {
      this.$refs.inputField.click();
    },
  },
  created() {
    if (this.srcImage) {
      this.srcImagePreview = this.srcImage;
    } else {
      this.emptyInputField = true;
    }
  },
};
</script>

<style scoped>
.image-input__input-wrapper {
  /* height: 200px; */
  /* width: 200px; */
  border: 1px solid #757575 !important;
  cursor: pointer;
}

.empty-input__absolute-info {
  position: absolute;
}

.image-input__empty-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
