import axios from "axios";
import { ValidationError } from "@/error/Validation-Error";
import toast from "@/plugins/toast";

export class Request {
  constructor({
    baseURL = "https://back.samainnovation.com/api/",
    method,
    endpoint,
    data,
    params,
    responseType,
    cancelToken,
  }) {
    this.baseURL = baseURL;
    this.progress = 0;
    this.method = method;
    this.endpoint = endpoint;
    this.data = data;
    this.token = $cookies.get("token");
    this.params = params;
    this.responseType = responseType;
    this.cancelToken = cancelToken;
  }

  async send(onUploadProgress) {
    try {
      let response = await axios({
        baseURL: this.baseURL,
        method: this.method,
        url: this.endpoint,
        data: this.data,
        headers: { Authorization: `Bearer ${this.token}` },
        params: this.params,
        responseType: this.responseType,
        onUploadProgress: onUploadProgress,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      if (error.toJSON().message === "Network Error") {
        toast(i18n.t("Internet Error"), { timeout: 4000, rtl: true }, "error");

        throw new ValidationError("Internet Error", "INTERNET ERROR");
      } else if (error.response.data.status === "fail") {
        let message = Array.isArray(error.response.data.error.serial)
          ? error.response.data.error.serial[0]
          : error.response.data.error;
        throw new ValidationError(this.errorMapper(message), "USER ERROR");
      }
    }
  }

  errorMapper(type) {
    const error = new Map([
      ["No internet", "Internet Error"],
      ["ERR_TIMED_OUT", "Internet Error"],
      ["ERR_NAME_NOT_RESOLVED", "Internet Error"],
      ["ERR_INTERNET_DISCONNECTED", "Internet Error"],
      ["INVALID_CREDENTIALS", "error in login"],
      ["PASSWORD_INVALID", "invalid password"],
      ["VERIFY_TOKEN_INVALID", "Invalid Verify Code"],
      ["RESET_CODE_INVALID", "Invalid Reset Code"],
      ["RESOURCE_NOT_FOUND", "RESOURCE_NOT_FOUND"],
      ["NOT_VERIFIED", "you are not verify"],
      ["UNACCEPTABLE_OPERATION", "Error in Verify Code"],
      ["The reset code is not valid", "The reset code is not valid"],
      ["The name field must be a string.", "The name field must be a string."],
      ["folder name already exists", "folder name already exists"],
    ]);
    return error.get(type) || type;
  }
}
