<template>
  <v-row class="justify-center align-start ">
    <v-col lg="5" v-if="type == 'base'">
      <v-text-field
        v-model="data.text"
        @input="$emit('textLoaded', $event)"
        :disabled="disable"
        :label="$t(label)"
        persistent-hint
        outlined
      ></v-text-field>
    </v-col>
    <v-col v-else lg="4">
      <v-text-field
        v-model="data.text"
        @input="$emit('textLoaded', $event)"
        :label="$t(label)"
        persistent-hint
        outlined
        :append-icon="isGabs ? 'mdi-plus' : null"
        @keydown="handleKeyDown"
        @click:append="addGab"
      ></v-text-field>
    </v-col>

    <audio-preview
      @fileIdLoaded="$emit('fileIdLoaded', $event)"
      @cancelFile="$emit('fileIdLoaded', null)"
      :data="data.audio == -1 ? null : data.audio"
    ></audio-preview>
  </v-row>
</template>
<script>
import audioPreview from "../preview/audio.vue";
export default {
  props: {
    label: {
      type: String,
      default: "base_question",
    },
    type: {
      type: String,
    },
    disable: {
      type: Boolean,
    },
    fillData: {
      type: Object,
    },
    isGabs: {
      type: Boolean,
    },
  },
  components: {
    audioPreview,
  },
  data() {
    return {
      data: {
        text: null,
        audio: null,
      },
      gabsNumber: 0,
    };
  },
  methods: {
    addGab() {
      let gabPosition = (this.data.text || "").length;
      if (this.data.text == null) {
        this.data.text = ""; // Initialize with an empty string
      }
      this.data.text += " ____ ";
      this.gabsNumber += 1;
      this.$emit("addedGab", gabPosition);
      this.$emit("textLoaded", this.data.text);
    },
    handleKeyDown(event) {
      // Prevent the Backspace and Delete keys from deleting characters
      if (!this.isGabs) return;
      if (event.key === "Delete") {
        event.preventDefault();
      }
      if (event.key === "Backspace") {
        if (this.data.text == "") {
          this.$emit("deleteAll");
          this.$emit("textLoaded", this.data.text);
          this.gabsNumber = 0;

          return;
        }
        if (
          this.data.text.endsWith(" ____ ") ||
          this.data.text.endsWith(" ____  ") ||
          this.data.text.endsWith("  ____  ") ||
          this.data.text.endsWith("  ____ ")
        ) {
          let data = this.data.text.slice(0, -4);
          this.$emit("deleteGab");
          this.$emit("textLoaded", data);
          this.gabsNumber = 0;
        }
      }
      this.$emit("textLoaded", this.data.text);
      return;
    },
  },

  created() {
    if (this.fillData) {
      this.data = this.fillData;
      if (this.isGabs) this.gabsNumber = 1;
    }
  },
};
</script>
