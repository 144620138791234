import { State } from "../state";
import { Request } from "@/network/request";
import router from "../../router/index";
import i18n from "../../i18n";
import toast from "../../plugins/toast";

export default class exercise {
  biggestItems = [];
  smallerList = [];
  exerciseName = null;
  postType = null;
  types = [
    { value: "video", text: i18n.t("video") },
    { value: "presentation", text: i18n.t("presentation") },
    { value: "voice_record", text: i18n.t("voice_record") },
    { value: "cross_words", text: i18n.t("cross_words") },
    { value: "choose", text: i18n.t("choose") },
    { value: "puzzle", text: i18n.t("puzzle") },
    { value: "match", text: i18n.t("match") },
    { value: "true_or_false", text: i18n.t("true_or_false") },
    { value: "reorder", text: i18n.t("reorder") },
    { value: "coloring", text: i18n.t("coloring") },
    { value: "maze", text: i18n.t("maze") },
    { value: "memory", text: i18n.t("memory") },
    { value: "categorize", text: i18n.t("categorize") },
    { value: "fill_gaps", text: i18n.t("fill_gaps") },
  ];
  tableData = {
    data: [],
    lessonData: [],
    headers: {
      id: {
        name: "id",
        keys: ["serial"],
      },

      name: {
        name: "name",
        keys: ["name"],
      },

      type: {
        name: "type",
        keys: ["type"],
      },
      AgeGroup: {
        name: "age group",
        keys: ["age_group", "name"],
      },
      skilles: {
        name: "skilles",
        keys: ["skills"],
        arrayKey: ["name"],
      },
      description: {
        name: "description",
        keys: ["description"],
      },

      date: {
        name: "date",
        keys: ["created_at"],
      },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
    lessonDataMeta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  exercise = {
    name: null,
    serial: null,
    description: null,
    age_group_id: null,
    individual_differences: null,
    type: null,
    schema: {
      questions: [
        {
          title: "sky is blue",
          right: "true",
        },
        {
          title: "sky is red",
          right: "false",
        },
      ],
    },
    background_sound: 1,
    skills: [],
  };

  getState = new State();
  getByLessonIdState = new State();
  showState = new State();
  postState = new State();
  editState = new State();
  deleteState = new State();
  exerciseprefState = new State();
  get(page, { onSuccess } = {}) {
    let request = new Request({
      method: "get",
      endpoint: `/exercise`,
      params: page,
      token: this.token,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.exercises;
          this.tableData.meta = value.meta;
          if (typeof onSuccess == "function") onSuccess(value);
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async getBySerial(page, { onSuccess } = {}) {
    let request = new Request({
      method: "get",
      endpoint: `/exercise`,
      params: page,
      token: this.token,
    });

    await this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          if (typeof onSuccess == "function") onSuccess(value);
        },
      }
    );
  }
  getExerciseForteacher(params) {
    let request = new Request({
      method: "get",
      endpoint: `/teacher/lessonExercise`,
      params: params,
      token: this.token,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.data;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async postExercisePreference(payload, id) {
    let request = new Request({
      method: "put",
      endpoint: `/lessonExercise/dependance/${id}`,
      data: payload,
      token: this.token,
    });
    await this.exerciseprefState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "success"
          );
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  getByLessonId(params) {
    let request = new Request({
      method: "get",
      endpoint: `/exercise`,
      params: params,
    });

    this.getByLessonIdState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.lessonData = value.exercises;
          this.tableData.lessonDataMeta = value.meta;
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async post(payload) {
    let request = new Request({
      method: "post",
      endpoint: `/exercise`,
      data: payload,
      token: this.token,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
          router.push("/Exercise/table");
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  put(payload, id) {
    let request = new Request({
      method: "put",
      endpoint: `/exercise/${id}`,
      data: payload,
      token: this.token,
    });

    this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          router.push("/Exercise/table");
          toast(i18n.t("edit success"), { timeout: 4000, rtl: true }, "sucess");
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async show(id, type) {
    let request = new Request({
      method: "get",
      endpoint: `/exercise/${id}`,
      token: this.token,
    });

    await this.showState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.exercise = value.exercise;
          this.exercise.age_group_id = value.exercise.age_group.id;
          let skills = [];
          this.exercise.skills.forEach((item) => {
            skills.push(item.id);
          });
          delete this.exercise.skills;
          Object.assign(this.exercise, { skills: skills });
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async delete(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/exercise/${id}`,
      token: this.token,
    });

    await this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async reOrderExercises(payload) {
    let request = new Request({
      method: "put",
      endpoint: `/lessonExercise/orders`,
      data: payload,
    });

    await this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(i18n.t("edit success"), { timeout: 4000, rtl: true }, "sucess");
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  clearData() {
    (this.exercise.name = null),
      (this.exercise.serial = null),
      (this.exercise.description = null),
      (this.exercise.age_group_id = null),
      (this.exercise.individual_differences = null),
      (this.exercise.type = null),
      (this.exercise.skills = []);
  }
}
export const Exercise = new exercise();
