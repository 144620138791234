<template>
  <div class="text-center">
    <v-snackbar
      style="position: fixed;cursor: pointer;"
      v-for="(item, index) in uploadData"
      :key="index"
      :style="{ bottom: index * 60 + 'px' }"
      v-model="show"
      :bottom="true"
      :right="true"
      timeout="0"
      color="blue-grey"
    >
      {{ $t("uploading") }}
      {{ shortName(item.name) }}

      <template v-slot:action="{ attrs }">
        <v-fade-transition>
          <v-icon x-large v-if="showCancel" transition="fade-transition"
            >mdi-close-circle-outline</v-icon
          >
          <v-progress-circular
            v-else
            v-bind="attrs"
            :rotate="360"
            :size="30"
            :width="3"
            :value="item.percent"
          >
            <span class="white--text"> {{ item.percent }}</span>
          </v-progress-circular>
        </v-fade-transition>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { File } from "@/store/file-system/file";
export default {
  data() {
    return {
      show: true,
      File,
      snackbars: [],
      multiLine: false,
      showCancel: false,
      text: "This is a snackbar!",
    };
  },
  methods: {
    removeSnackbar(index) {
      this.snackbars.splice(index, 1);
    },
    shortName(name) {
      return name.substring(0, 20);
    },
  },
  computed: {
    uploadData() {
      return File.uploadData;
    },
  },
};
</script>
