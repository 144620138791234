<template>
  <v-expand-transition mode="out-in">
    <v-row class="add-onSide file-label  justify-center align-center">
      <input-file
        :createFeature="true"
        :imgHeight="230"
        :imgWidth="230"
        :resetImage="reset"
        :placeHolder="$t('click_to_upload_file')"
        @fileValue="setFile($event)"
        :accept="accept"
      ></input-file>
    </v-row>
  </v-expand-transition>
</template>
<script>
import inputFile from "@/components/mycomponent/base/inputFile.vue";
export default {
  props: ["reset", "accept"],
  components: {
    inputFile,
  },
  methods: {
    setFile(file) {
      this.$emit("fileValue", file);
    },
  },
};
</script>
