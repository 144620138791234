<!-- eslint-disable -->
<template>
  <!-- width and height are optional -->
  <div class="d-flex justify-center align-center loader">
    <lottie :options="lottieOptions" v-on:animCreated="handleAnimation" />
  </div>
</template>
<script>
import lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "../../../assets/loader.json";
export default {
  components: {
    lottie,
  },
  data() {
    return {
      anim: null, // for saving the reference to the animation
      lottieOptions: { animationData: animationData.default },
    };
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
  },
};
</script>
<style lang="scss" scoped>
.loader {
  width: 100%;
  aspect-ratio: 5/1;
}
</style>
