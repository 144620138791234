<template>
  <div>
    <!-- <v-list-group
      v-if="item.children"
      :prepend-icon="item.icon"
      :value="item.active"
    >
      <template v-slot:activator>
        <v-list-item @click="activate" :to="item.route">
          <v-list-item-content class="mx-n4">
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-for="(childItem, childIndex) in item.children"
        :key="childIndex"
        :to="childItem.route"
        :class="{ active: childItem.active }"
        @click="activateChildItem(childItem)"
      >
        <v-list-item-icon>
          <v-icon>{{ childItem.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t(childItem.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group> -->
    <v-list-item
      class="my-2"
      :ripple="false"
      active-color="white"
      @click="activate"
      :to="item.route"
      link
    >
      <v-list-item-icon>
        <v-icon class="icon" :mdi="item.icon">{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      active: null,
    };
  },
  methods: {
    activate() {
      this.$emit("activate", this.item);
    },
    activateChildItem(childItem) {
      this.$emit("activate", childItem);
    },
  },
};
</script>
<style lang="scss" scoped>
.md-theme-default a:not(.md-button) {
  color: white;
  color: var(white);
}
</style>
