import toast from "@/plugins/toast";
import i18n from "@/i18n";
import { Auth } from "@/store/common/auth";
export default function isAdmin({ next, router }) {
  if (Auth.userType == "admin") {
    return next();
  }
  toast(i18n.t("not allowed"), { timeout: 8000, rtl: true }, "error");
  router.push(-1);
}
