<template>
  <div class="d-block">
    <v-row v-if="type == 'fill-spaces'">
      <v-btn width="40%" class="ma-auto mt-4 mb-n1" color="info">{{
        itemNumber + 1
      }}</v-btn></v-row
    >
    <v-container style="background-color: white;height: " class="option my-4">
      <v-row v-if="type != 'power-point'" class="align-center mb-n10">
        <v-col lg="2" md="2" sm="2" cols="2">
          <v-radio-group
            @click="resetInfo('img')"
            class="mt-n2"
            v-model="slide.type"
            column
          >
            <v-radio
              @click="resetInfo('img')"
              color="primary"
              value="text"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col lg="10"
          ><v-text-field
            @input="setFiles($event, 'text')"
            :disabled="slide.type == 'img'"
            v-bind:value="slide.type == 'text' ? slide.text : null"
            :label="$t('answer_text')"
            outlined
            dense
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col v-if="type != 'power-point'" lg="2" md="2" sm="2" cols="2">
          <v-radio-group @click="resetInfo('text')" v-model="slide.type" column>
            <v-radio
              @click="resetInfo('text')"
              color="primary"
              value="img"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <image-preview
            @fileIdLoaded="setFiles($event, 'img')"
            @cancelFile="setFiles(null, 'img')"
            v-bind:disabled="slide.type == 'img' ? false : true"
            v-bind:data="slide.type == 'img' ? slide.image : null"
        /></v-col>
      </v-row>

      <v-row v-if="type != 'memory-game'" class="justify-center mt-n3">
        <v-col class="d-flex justify-center"
          ><audio-preview
            @fileIdLoaded="setFiles($event, 'audio')"
            @cancelFile="setFiles(null, 'audio')"
            :data="slide.audio"
          ></audio-preview
        ></v-col>
      </v-row>
    </v-container>

    <v-row v-if="type == 'rearrange'">
      <v-btn width="40%" class="ma-auto mt-n1 mb-4" color="info">{{
        itemNumber + 1
      }}</v-btn></v-row
    >
    <v-row v-if="type == 'true-false'" class="justify-center mt-2">
      <v-switch
        @click="setAnswerTrue()"
        v-model="switch1"
        inset
        class="ma-1"
        :label="$t('true')"
      ></v-switch>
      <v-switch
        @click="setAnswerFalse()"
        v-model="switch2"
        inset
        class="ma-1"
        :label="$t('false')"
      ></v-switch>
    </v-row>
  </div>
</template>
<script>
import audioPreview from "@/components/mycomponent/preview/audio.vue";
import imagePreview from "@/components/mycomponent/preview/image.vue";
export default {
  props: ["type", "itemNumber", "data"],
  components: {
    audioPreview,
    imagePreview,
  },
  data() {
    return {
      slide: {
        image: null,
        text: null,
        audio: null,
        type: "text",
      },
      switch1: true,
      switch2: false,
    };
  },
  methods: {
    setFiles(file, type) {
      if (type == "img") {
        this.slide.image = file;
      } else if (type == "text") {
        this.slide.text = file;
      } else {
        this.slide.audio = file;
      }
      if (this.type == "true-false") {
        Object.assign(this.slide, {
          answer: this.switch1,
        });
      }
      this.$emit("dataLoaded", this.slide);
    },
    resetInfo(type) {
      if (type == "text") {
        this.slide.text = null;
      } else {
        this.slide.image = null;
      }
      this.$emit("dataLoaded", this.slide);
    },
    setAnswerFalse() {
      (this.switch2 = true), (this.switch1 = false);
      Object.assign(this.slide, {
        answer: this.switch1,
      });
      this.$emit("dataLoaded", this.slide);
    },
    setAnswerTrue() {
      (this.switch2 = false), (this.switch1 = true);
      Object.assign(this.slide, {
        answer: this.switch1,
      });
      this.$emit("dataLoaded", this.slide);
    },
  },

  created() {
    if (this.type == "power-point") {
      this.slide.type = "img";
    }
    if (this.data) {
      this.slide = this.data;
      if (!this.data.type) {
        if (this.data.text == null) {
          Object.assign(this.data, { type: "img" });
        } else {
          Object.assign(this.data, { type: "text" });
        }
      }
    }
    if (this.type == "true-false") {
      this.switch1 = this.data.answer;
      this.switch2 = !this.data.answer;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";

.option {
  display: block;
  border: 3px solid $myBlue;
  border-radius: 20px;
  .divider {
    color: $myBlue;
  }
}
</style>
